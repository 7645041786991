<template>
    <div style="padding:0 16px 0 ">
        <a-form layout="horizontal" style="padding:10px;height:58px;">
            <a-form-item>
                <a-radio-group default-value="-1" @change="radioChange">
                    <a-radio-button value="-1">
                        全部
                    </a-radio-button>
                    <a-radio-button value="1">
                        访问
                    </a-radio-button>
                    <a-radio-button value="5">
                        拉新
                    </a-radio-button>
                    <a-radio-button value="6">
                        意向
                    </a-radio-button>
                    <a-radio-button value="3">
                        成交
                    </a-radio-button>
                </a-radio-group>
            </a-form-item>
        </a-form>
        <a-table :columns="columns"
                 rowKey="ID"
                 :data-source="tableData"
                 :pagination="pagination"
                 @change="pagerChange"
                 class="yj-table-img">
            <span slot="action" slot-scope="text, record">
                <a>{{ record.ID==null?'操作':'操作' }}</a>
            </span>
            <span slot="FinishType" slot-scope="text, record">{{ ShowEnum(record.FinishType) }}</span>
        </a-table>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "访问信息",
                        width: 400,
                        align: "left",
                        customRender: (text, item) => {
                            var Name = item.Name == null ? '' : item.Name;
                            var Phone = item.Phone == null ? '' : util.GetPhone(item.Phone);
                            return <div><img src={item.HeadImg} width="50px" height="50px" style="float: left;margin-right: 10px;" /><label>{Name}</label><br /><label style="font-size:12px;" >{Phone}</label></div>;
                        }
                    },
                    {
                        title: "状态",
                        width: 200,
                        scopedSlots: { customRender: 'FinishType' }
                    },
                    {
                        title: "订单",
                        align: "center",
                        width: 520,
                        customRender: (text, item) => {
                            var rt;
                            if (item.FinishType == 3) {
                                rt = util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss') + ' ' + item.Memo;
                            }
                            return rt;
                        }
                    }
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                TaskType: "-1",
                jsondata: []
            };
        },
        props: {//组件属性
            id: {
                type: String,
                default: "0"
            }
        },
        methods: {//方法
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Task/GetFinishList",
                    data: {
                        pageIndex: self.pagination.current,
                        id: self.id,
                        TaskType: self.TaskType
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            radioChange: function (item) {
                var self = this;
                self.TaskType = item.target.value;
                self.pagination.current = 1;
                self.getTableData();
            },
            getData2: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.TaskModule",//程序集名称
                        TypeName: "Welcome.TaskModule.Enumerate.TaskTargetTypeEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getData2();
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>