<template>
    <div style="padding:16px 16px 0">
        <a-table :columns="columns"
                 rowKey="ID" style="min-width:max-content;width:calc(100% - 0px);"
                 :data-source="tableData"
                 :pagination="pagination"
                 @change="pagerChange"
                 class="yj-table-img">
            <span slot="action" slot-scope="text, record">
                <a>{{ record.ID==null?'操作':'操作' }}</a>
            </span>
            <span slot="name" slot-scope="text, record">
                <a @click="PartnerDetail(record)" style="cursor:pointer;"><img :src="record.HeadImg" width="50" height="50" style="float: left;margin-right: 10px;" /><p>{{record.Name }}<br /><label style="font-size:12px;cursor:pointer;">{{record.Phone }}</label></p></a>
            </span>
            <span slot="FinishStatus" slot-scope="text, record">{{ ShowEnum(record.FinishStatus) }}</span>
        </a-table>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "名次",
                        dataIndex: "ID",
                        width: 120,
                    },
                    {
                        title: "合伙人信息",
                        width: 500,
                        align: "left",
                        scopedSlots: { customRender: 'name' },
                    },
                    {
                        title: "状态",
                        width: 200,
                        scopedSlots: { customRender: 'FinishStatus' }
                    },
                    {
                        title: "任务进度",
                        width: 300,
                        customRender: (text, item) => {
                            var rt;
                            console.log("******");
                            console.log(item.FinishStatus);
                            console.log("******");
                            switch (item.FinishStatus) {
                                case 0:
                                    
                                    rt = <div>阅读 {item.ReadFinish}/{item.ReadTarget}<br />成交 {item.ReportFinish}/{item.ReportTarget}<br /> 拉新 {item.NewUserFinish}/{item.NewUserTarget}<br />意向 {item.WantUsertFinish}/{item.WantUserTarget}</div>;
                                    break;
                                case 1:
                                    rt = util.TimeStamptoDateTime(item.FinishTime, 'yyyy年MM月dd日 hh:mm');
                                    break;
                                case 2:
                                    rt = <div>阅读 {item.ReadFinish}/{item.ReadTarget}<br />成交 {item.ReportFinish}/{item.ReportTarget}<br /> 拉新 {item.NewUserFinish}/{item.NewUserTarget}<br />意向 {item.WantUsertFinish}/{item.WantUserTarget}</div>;
                                    //rt = <div>阅读 {item.ReadFinish}/{item.ReadTarget}<br />咨询 {item.ConsultFinish}/{item.ConsultTarget}</div>;
                                    break;
                            }
                            return rt;
                        }
                    },
                    //{
                    //    title: '操作',
                    //    align: "center",
                    //    width: 300,
                    //    scopedSlots: { customRender: 'action' },
                    //}
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                jsondata: []
            };
        },
        props: {//组件属性
            id: {
                type: String,
                default: "0"
            }
        },
        methods: {//方法
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Task/GetUserTaskList",
                    data: {
                        pageIndex: self.pagination.current,
                        id: self.id
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            PartnerDetail(e) {
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: { ID: e.UserID }
                });
            },
            Getimg(e) {
                return e.HeadImg;
            },
            getData2: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumFinishStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getData2();
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
