<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height: 100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);min-width:max-content;">
                    <a-layout style="height:100%;">
                        <a-layout-header style="background-color:#fff; margin-bottom:14px;height:auto;padding:0;">
                            <a-card title="任务详情" :bordered="false">
                                <a-row type="flex" justify="space-between">
                                    <a-col :span="9" class="gutter-row">
                                        <div class="gutter-box" style="box-shadow:none">
                                            <a-row type="flex" justify="center" align="middle" class="textal_c ">
                                                <a-col :span="24" style="text-align:left;padding-bottom:24px;">任务完成进度</a-col>
                                                <a-col :span="8">
                                                    <h2 class="ay-card-h2">{{ tableInfo.ConductCount }}</h2>
                                                    <small>进行中</small>
                                                </a-col>
                                                <a-col :span="8">
                                                    <h2 class="ay-card-h2">{{ tableInfo.FinishCount }}</h2>
                                                    <small>已完成</small>
                                                </a-col>
                                                <a-col :span="8">
                                                    <h2 class="textcl_orange ay-card-h2">{{ tableInfo.SaleMoney }}</h2>
                                                    <small>累计奖金</small>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-col>
                                    <a-col :span="10" class="gutter-row">
                                        <div class="gutter-box" style="box-shadow:none">
                                            <a-row type="flex" justify="center" align="middle" class="textal_c ">
                                                <a-col :span="24" style="text-align:left;padding-bottom:24px;">流量</a-col>
                                                <a-col :span="6">
                                                    <h2 class="ay-card-h2">{{ tableInfo.VisitCount }}</h2>
                                                    <small>访问</small>
                                                </a-col>
                                                <a-col :span="6">
                                                    <h2 class="ay-card-h2">{{ tableInfo.NewCount }}</h2>
                                                    <small>拉新</small>
                                                </a-col>
                                                <a-col :span="6">
                                                    <h2 class="ay-card-h2">{{ tableInfo.IntentionCount }}</h2>
                                                    <small>意向</small>
                                                </a-col>
                                                <a-col :span="6">
                                                    <h2 class="textcl_blue ay-card-h2">{{ tableInfo.DealCount }}</h2>
                                                    <small>成交</small>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-col>
                                </a-row>
                            </a-card>
                        </a-layout-header>
                        <a-layout-content style="background-color:#fff;">
                            <a-card :tab-list="tabListNoTitle"
                                    :active-tab-key="noTitleKey"
                                    @tabChange="key => onTabChange(key, 'noTitleKey')" :bordered="false">
                            </a-card>
                            <div v-if="noTitleKey=='completion'">
                                <UserTaskComplete :id="id" />
                            </div>
                            <div v-if="noTitleKey=='participation'">
                                <UserTaskParticipation :id="id" />
                            </div>
                        </a-layout-content>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import UserTaskComplete from "@/Views/TaskManageModule/Task/UserTaskComplete";
    import UserTaskParticipation from "@/Views/TaskManageModule/Task/UserTaskParticipation";
    export default {
        name: "",
        data() {
            return {
                tabListNoTitle: [
                    {
                        key: 'completion',
                        tab: '任务完成情况',
                    },
                    {
                        key: 'participation',
                        tab: '任务参与情况',
                    },
                ],
                noTitleKey: 'completion',
                tableInfo: {},
                id: 0
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Task/GetUserTaskInfo",
                    data: {
                        id: self.id
                    },
                    OnSuccess: function (data) {
                        self.tableInfo = data.data;
                        self.tableInfo.SaleMoney = util.ToFixed(self.tableInfo.SaleMoney, '');
                    }
                };
                http.Post(op);
            },
            onTabChange(key, type) {
                var self = this;
                console.log(type);
                self.noTitleKey = key;
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            UserTaskComplete,
            UserTaskParticipation
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.id = this.$route.query.id;
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>